import Vue from "vue";
import { URLS } from "@/api/urls.js";
// import store from "@/store/index.js";

const Apis = {
  // =====================================================================================
  // === API Test ===
  // =====================================================================================
  async getIpClient(callBackFunction) {
    const { data } = await Vue.axios.get('https://ipinfo.io/json?token=7bf79be6d4dfb5');
    return callBackFunction(data);
  },

  async goodLuck(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.test.goodLuck,
      JSON.stringify(params)
    )
    return callBackFunction(data);
  },

  // === 축평원 인증번호 받기 API ===
  async mtraceVerifyNo(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      'https://api.mtrace.go.kr/rest/verifyNo/userHistInfoUseAgree/verifyNoReq',
      JSON.stringify(params)
    )
    return callBackFunction(data);
  },
  async userHistInfoSvcUseAgreeReq(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      'https://api.mtrace.go.kr/rest/verifyNo/userHistInfoUseAgree/userHistInfoSvcUseAgreeReq',
      JSON.stringify(params)
    )
    return callBackFunction(data);
  },
  async deleteUserHistInfoSvcUseAgree(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      'https://api.mtrace.go.kr/rest/verifyNo/userHistInfoUseAgree/deleteUserHistInfoSvcUseAgree',
      JSON.stringify(params)
    )
    return callBackFunction(data);
  },
  // =====================================================================================
  // === user controller ===
  // =====================================================================================
  async login(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.user.login,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async logout(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.user.logout,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async pageCount(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.user.pageCount,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async findID(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.user.findID,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async tempPassword(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.user.tempPassword,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async changePassword(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.user.changePassword,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async accountCheck(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.user.accountCheck,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async insertUser(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.user.insertUser,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async joinUser(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.user.joinUser,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async searchCompanies(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.user.searchCompanies,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async updateUser(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.user.updateUser,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async updateUserFree(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.user.updateUserFree,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async infoUser(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.user.infoUser,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async getUserType(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.user.getUserType,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async listUsers(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.user.listUsers,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async insertUsersControl(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.user.insertUsersControl,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async deleteUsersControl(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.user.deleteUsersControl,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async treeManagermentCompanies(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.user.treeManagermentCompanies,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async listAccesableServices(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.user.listAccesableServices,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  // =====================================================================================
  // === farm controller ===
  // =====================================================================================
  async insertCompany(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.farm.insertCompany,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async updateCompany(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.farm.updateCompany,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async updateCompanyFree(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.farm.updateCompanyFree,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async infoCompany(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.farm.infoCompany,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async comboCompanies(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.farm.comboCompanies,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async listCompanies(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.farm.listCompanies,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async insertFarm(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.farm.insertFarm,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async updateFarm(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.farm.updateFarm,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async updateFarmFree(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.farm.updateFarmFree,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async infoFarm( params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.farm.infoFarm,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async comboFarms(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.farm.comboFarms,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async listFarms(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.farm.listFarms,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async listFarmsForMap(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.farm.listFarmsForMap,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async insertBuildingOne(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.farm.insertBuildingOne,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async updateBuildingTwo(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.farm.updateBuildingTwo,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async updateBuildingFree(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.farm.updateBuildingFree,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async infoBuilding(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.farm.infoBuilding,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async comboBuildings(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.farm.comboBuildings,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async listBuildings(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.farm.listBuildings,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async insertRoom(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.farm.insertRoom,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async saveRooms(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.farm.saveRooms,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async updateRoom(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.farm.updateRoom,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async updateRoomFree(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.farm.updateRoomFree,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async infoRoom( parmas , callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.farm.infoRoom,
      JSON.stringify(parmas)
    );
    return callBackFunction(data);
  },
  async infoRooms( parmas , callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.farm.infoRooms,
      JSON.stringify(parmas)
    );
    return callBackFunction(data);
  },
  async listRooms( parmas, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.farm.listRooms,
      JSON.stringify(parmas)
    );
    return callBackFunction(data);
  },
  async comboRooms( parmas, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.farm.comboRooms,
      JSON.stringify(parmas)
    );
    return callBackFunction(data);
  },
  async numberOfRooms(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.farm.numberOfRooms,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async insertSection(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.farm.insertSection,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async saveSections(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.farm.saveSections,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async updateSection(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.farm.updateSection,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async updateSectionFree(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.farm.updateSectionFree,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async infoSections(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.farm.infoSections,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async comboSections(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.farm.comboSections,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async insertZone(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.farm.insertZone,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async saveZones(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.farm.saveZones,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async updateZone(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.farm.updateZone,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async updateZoneFree(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.farm.updateZoneFree,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async infoZones(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.farm.infoZones,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async comboZones(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.farm.comboZones,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async listSensorsInFarmForMap(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.farm.listSensorsInFarmForMap,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },


  //지도 정보
  async getAddressCoordinatesOfFarm(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.farm.getAddressCoordinatesOfFarm,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },

  // =====================================================================================
  // === setup controller ===
  // =====================================================================================
  async insertDealCompany(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.setup.insertDealCompany,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async updateDealCompany(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.setup.updateDealCompany,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async infoDealCompany(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.setup.infoDealCompany,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async comboDealCompanies(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.setup.comboDealCompanies,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async listDealCompanies(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.setup.listDealCompanies,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async listWeightPerDay(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.setup.listWeightPerDay,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  // =====================================================================================
  // === event controller ===
  // =====================================================================================
  async pigcntInSection(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.event.pigcntInSection,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async saveEnterQuick(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.event.saveEnterQuick,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  // async saveEnterInside(params, callBackFunction) {
  //   const { data } = await Vue.axios.post(
  //     URLS.event.saveEnterInside,
  //     JSON.stringify(params)
  //   );
  //   return callBackFunction(data);
  // },
  // async saveEnterOutside(params, callBackFunction) {
  //   const { data } = await Vue.axios.post(
  //     URLS.event.saveEnterOutside,
  //     JSON.stringify(params)
  //   );
  //   return callBackFunction(data);
  // },
  async saveTransferQuick(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.event.saveTransferQuick,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  // async saveTransferInside(params, callBackFunction) {
  //   const { data } = await Vue.axios.post(
  //     URLS.event.saveTransferInside,
  //     JSON.stringify(params)
  //   );
  //   return callBackFunction(data);
  // },
  // async saveTransferOutside(params, callBackFunction) {
  //   const { data } = await Vue.axios.post(
  //     URLS.event.saveTransferOutside,
  //     JSON.stringify(params)
  //   );
  //   return callBackFunction(data);
  // },
  async saveSaleQuick(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.event.saveSaleQuick,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  // async saveSaleButcher(params, callBackFunction) {
  //   const { data } = await Vue.axios.post(
  //     URLS.event.saveSaleButcher,
  //     JSON.stringify(params)
  //   );
  //   return callBackFunction(data);
  // },
  // async saveSaleConsignment(params, callBackFunction) {
  //   const { data } = await Vue.axios.post(
  //     URLS.event.saveSaleConsignment,
  //     JSON.stringify(params)
  //   );
  //   return callBackFunction(data);
  // },
  async saveDeathQuick(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.event.saveDeathQuick,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  // async saveDeath(params, callBackFunction) {
  //   const { data } = await Vue.axios.post(
  //     URLS.event.saveDeath,
  //     JSON.stringify(params)
  //   );
  //   return callBackFunction(data);
  // },
  async infoEvent(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.event.infoEvent,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async deleteEvent(params, callBackFunction) {
    // console.log(params);
    const { data } = await Vue.axios.post(
      URLS.event.deleteEvent,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async listEventMemos(params, callBackFunction) {
    // console.log(params);
    const { data } = await Vue.axios.post(
      URLS.event.listEventMemos,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async roomDailyCountByEvent(params, callBackFunction) {
    // console.log(params);
    const { data } = await Vue.axios.post(
      URLS.event.roomDailyCountByEvent,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },

  async comboDeathCause(params, callBackFunction) {
    // console.log(params);
    const { data } = await Vue.axios.post(
      URLS.event.comboDeathCause,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  // === schedule controller ===
  async listFarmEvent(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.schedule.listFarmEvent,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  // async listGetoutSchedule(params, callBackFunction) {
  //   // console.log(params);
  //   const { data } = await Vue.axios.post(
  //     URLS.schedule.listGetoutSchedule,
  //     JSON.stringify(params)
  //   );
  //   return callBackFunction(data);
  // },
  // =====================================================================================
  // === device controller ===
  // =====================================================================================
  async listSensorsOnOff(params, callBackFunction) {
    // console.log(params);
    const { data } = await Vue.axios.post(
      URLS.device.listSensorsOnOff,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async listSensors(params, callBackFunction) {
    // console.log(params);
    const { data } = await Vue.axios.post(
      URLS.device.listSensors,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async listSensorsInUnit(params, callBackFunction) {
    // console.log(params);
    const { data } = await Vue.axios.post(
      URLS.device.listSensorsInUnit,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async comboSensors(params, callBackFunction) {
    // console.log(params);
    const { data } = await Vue.axios.post(
      URLS.device.comboSensors,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async comboSensorData(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.device.comboSensorData,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },

  async infoSensorByID(params, callBackFunction) {
    // console.log(params);
    const { data } = await Vue.axios.post(
      URLS.device.infoSensorByID,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async infoSensorByMac(params, callBackFunction) {
    // console.log(params);
    const { data } = await Vue.axios.post(
      URLS.device.infoSensorByMac,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async insertSensor(params, callBackFunction) {
    // console.log(params);
    const { data } = await Vue.axios.post(
      URLS.device.insertSensor,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async deleteSensor(params, callBackFunction) {
    // console.log(params);
    const { data } = await Vue.axios.post(
      URLS.device.deleteSensor,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async updateSensor(params, callBackFunction) {
    // console.log(params);
    const { data } = await Vue.axios.post(
      URLS.device.updateSensor,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async updateSensorFree(params, callBackFunction) {
    // console.log(params);
    const { data } = await Vue.axios.post(
      URLS.device.updateSensorFree,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async insertController(params, callBackFunction) {
    // console.log(params);
    const { data } = await Vue.axios.post(
      URLS.device.insertController,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async updateController(params, callBackFunction) {
    // console.log(params);
    const { data } = await Vue.axios.post(
      URLS.device.updateController,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async infoController(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.device.infoController,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async deleteController(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.device.deleteController,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async listControllers(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.device.listControllers,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async listControllerModels(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.device.listControllerModels,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async listFanModels(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.device.listFanModels,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async insertSmartbox(params, callBackFunction) {
    // console.log(params);
    const { data } = await Vue.axios.post(
      URLS.device.insertSmartbox,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async updateSmartbox(params, callBackFunction) {
    // console.log(params);
    const { data } = await Vue.axios.post(
      URLS.device.updateSmartbox,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async infoSmartbox(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.device.infoSmartbox,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async deleteSmartbox(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.device.deleteSmartbox,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async listSmartboxs(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.device.listSmartboxs,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async listSmart_mac(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.device.listSmart_mac,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async listSmartboxModels(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.device.listSmartboxModels,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async deleteDevice(params, callBackFunction) {
    // console.log(params);
    const { data } = await Vue.axios.post(
      URLS.device.deleteDevice,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async getNowControllerValue(params, callBackFunction) {
    // console.log(params);
    const { data } = await Vue.axios.post(
      URLS.device.getNowControllerValue,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async listControllerSetting(params, callBackFunction) {
    // console.log(params);
    const { data } = await Vue.axios.post(
      URLS.device.listControllerSetting,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  // async infoControllerStatus(params, callBackFunction) {
  //   const { data } = await Vue.axios.post(
  //     URLS.device.infoControllerStatus,
  //     JSON.stringify(params)
  //   );
  //   return callBackFunction(data);
  // },
  async comboData_names(params, callBackFunction) {
    // console.log(params);
    const { data } = await Vue.axios.post(
      URLS.device.comboData_names,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  
  async comboPLC(params, callBackFunction) {
    // console.log(params);
    const { data } = await Vue.axios.post(
      URLS.device.comboPLC,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },

  async insertDeodorizer(params, callBackFunction) {
    // console.log(params);
    const { data } = await Vue.axios.post(
      URLS.device.insertDeodorizer,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },

  async updateDeodorizer(params, callBackFunction) {
    // console.log(params);
    const { data } = await Vue.axios.post(
      URLS.device.updateDeodorizer,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },

  async deleteDeodorizer(params, callBackFunction) {
    // console.log(params);
    const { data } = await Vue.axios.post(
      URLS.device.deleteDeodorizer,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },

  async listDeodorizers(params, callBackFunction) {
    // console.log(params);
    const { data } = await Vue.axios.post(
      URLS.device.listDeodorizers,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },

  async infoDeodorizer(params, callBackFunction) {
    // console.log(params);
    const { data } = await Vue.axios.post(
      URLS.device.infoDeodorizer,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },

  async listDeodorizerModels(params, callBackFunction) {
    // console.log(params);
    const { data } = await Vue.axios.post(
      URLS.device.listDeodorizerModels,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async insertDeodorizerControlDevice(params, callBackFunction) {
    // console.log(params);
    const { data } = await Vue.axios.post(
      URLS.device.insertDeodorizerControlDevice,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async updateDeodorizerControlDevice(params, callBackFunction) {
    // console.log(params);
    const { data } = await Vue.axios.post(
      URLS.device.updateDeodorizerControlDevice,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async listDeodorizerControlDevices(params, callBackFunction) {
    // console.log(params);
    const { data } = await Vue.axios.post(
      URLS.device.listDeodorizerControlDevices,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async infoDeodorizerControlDevice(params, callBackFunction) {
    // console.log(params);
    const { data } = await Vue.axios.post(
      URLS.device.infoDeodorizerControlDevice,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async listDeodorizerControlDeviceModels(params, callBackFunction) {
    // console.log(params);
    const { data } = await Vue.axios.post(
      URLS.device.listDeodorizerControlDeviceModels,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async comboDeodorizerControlDevices(params, callBackFunction) {
    // console.log(params);
    const { data } = await Vue.axios.post(
      URLS.device.comboDeodorizerControlDevices,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async treeDeodorizerControlDevices(params, callBackFunction) {
    // console.log(params);
    const { data } = await Vue.axios.post(
      URLS.device.treeDeodorizerControlDevices,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async treeDeodorizerDviceMonitoring(params, callBackFunction) {
    // console.log(params);
    const { data } = await Vue.axios.post(
      URLS.device.treeDeodorizerDviceMonitoring,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  // async comboControlNotice(params, callBackFunction) {
  //   // console.log(params);
  //   const { data } = await Vue.axios.post(
  //     URLS.device.comboControlNotice,
  //     JSON.stringify(params)
  //   );
  //   return callBackFunction(data);
  // },

  // =====================================================================================
  // === 자동제어  ===
  // =====================================================================================
  async listMonitoringControllers(params, callBackFunction) {
    // console.log(params);
    const { data } = await Vue.axios.post(
      URLS.control.listMonitoringControllers,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async infoDetailRoomControllers(params, callBackFunction) {
    // console.log(params);
    const { data } = await Vue.axios.post(
      URLS.control.infoDetailRoomControllers,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async listSettingHistories(params, callBackFunction) {
    // console.log(params);
    const { data } = await Vue.axios.post(
      URLS.control.listSettingHistories,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async infoSettingHistory(params, callBackFunction) {
    // console.log(params);
    const { data } = await Vue.axios.post(
      URLS.control.infoSettingHistory,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async saveControllerSetting(params, callBackFunction) {
    // console.log(params);
    const { data } = await Vue.axios.post(
      URLS.control.saveControllerSetting,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async stopRemoteControl(params, callBackFunction) {
    // console.log(params);
    const { data } = await Vue.axios.post(
      URLS.control.stopRemoteControl,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async saveSeasonRange(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.control.saveSeasonRange,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async deleteSeasonRange(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.control.deleteSeasonRange,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async comboSeasonRanges(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.control.comboSeasonRanges,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async listSeasonRanges(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.control.listSeasonRanges,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async infoSeasonRange(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.control.infoSeasonRange,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async saveWeightRange(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.control.saveWeightRange,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async deleteWeightRange(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.control.deleteWeightRange,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async comboWeightRanges(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.control.comboWeightRanges,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async listWeightRanges(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.control.listWeightRanges,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async infoWeightRange(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.control.infoWeightRange,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async saveControlRangeSettings(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.control.saveControlRangeSettings,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async deleteControlRangeSettings(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.control.deleteControlRangeSettings,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async listControlRangeSettings(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.control.listControlRangeSettings,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async saveFanControlPreset(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.control.saveFanControlPreset,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async loadFanControlPreset(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.control.loadFanControlPreset,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async comboFanControlPresets(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.control.comboFanControlPresets,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async confirmFanControlPreset(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.control.confirmFanControlPreset,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },


  async infoDeodorizerSetting(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.control.infoDeodorizerSetting,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async listMonitorDeodorizers(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.control.listMonitorDeodorizers,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async saveDeodorizerSetting(params, callBackFunction) {
    // console.log(params);
    const { data } = await Vue.axios.post(
      URLS.control.saveDeodorizerSetting,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },

  async setStatusOfDeodorizer(params, callBackFunction) {
    // console.log(params);
    const { data } = await Vue.axios.post(
      URLS.control.setStatusOfDeodorizer,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },

  async deodorizerOnLines(params, callBackFunction) {
    // console.log(params);
    const { data } = await Vue.axios.post(
      URLS.control.deodorizerOnLines,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },

  async deodorizerOnAllLines(params, callBackFunction) {
    // console.log(params);
    const { data } = await Vue.axios.post(
      URLS.control.deodorizerOnAllLines,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },

  async deodorizerOffAllLines(params, callBackFunction) {
    // console.log(params);
    const { data } = await Vue.axios.post(
      URLS.control.deodorizerOffAllLines,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },

  async deodorizerAutoAllLines(params, callBackFunction) {
    // console.log(params);
    const { data } = await Vue.axios.post(
      URLS.control.deodorizerAutoAllLines,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },

  async deodorizerOffLines(params, callBackFunction) {
    // console.log(params);
    const { data } = await Vue.axios.post(
      URLS.control.deodorizerOffLines,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },

  async deodorizerAutoLines(params, callBackFunction) {
    // console.log(params);
    const { data } = await Vue.axios.post(
      URLS.control.deodorizerAutoLines,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },

  async deodorizerManualLines(params, callBackFunction) {
    // console.log(params);
    const { data } = await Vue.axios.post(
      URLS.control.deodorizerManualLines,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },

  // =====================================================================================
  // === monitor controller ===
  // =====================================================================================
  async buildingSummaryView(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.monitor.buildingSummaryView,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async farmSummaryView(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.monitor.farmSummaryView,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async roomDailyView(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.monitor.roomDailyView,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async room24hoursView(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.monitor.room24hoursView,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async roomRawDataView(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.monitor.roomRawDataView,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async monitoringSummary(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.monitor.monitoringSummary,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async monitoringWeather(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.monitor.monitoringWeather,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async roomMonitoringSummary(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.monitor.roomMonitoringSummary,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async monitoringAllRooms(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.monitor.monitoringAllRooms,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async weatherForDeodorizer(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.monitor.weatherForDeodorizer,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async summaryPigInfo(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.monitor.summaryPigInfo,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async listFarmKepcoData(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.monitor.listFarmKepcoData,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async kepcoDataDailyView(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.monitor.kepcoDataDailyView,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async listTimelyKepcoData(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.monitor.listTimelyKepcoData,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async kepcoDataMonthlyView(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.monitor.kepcoDataMonthlyView,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
    // =====================================================================================
  // === Esg ===
  // =====================================================================================
  async comboEsgFarms(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.esg.comboEsgFarms,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async monitoringEsgValueOfFarm(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.esg.monitoringEsgValueOfFarm,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async monitoringEsgValueOfFarm2(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.esg.monitoringEsgValueOfFarm2,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  
  // =====================================================================================
  // === alert controller ===
  // =====================================================================================
  async listAlertSetupOfFarm(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.alert.listAlertSetupOfFarm,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async saveAlertSetupOfFarm(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.alert.saveAlertSetupOfFarm,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async saveAlertSetupOfRoom(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.alert.saveAlertSetupOfRoom,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async savePersonalChoicedAlerts(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.alert.savePersonalChoicedAlerts,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async infoPersonalChoicedAlerts(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.alert.infoPersonalChoicedAlerts,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  // =====================================================================================
  // === alert data test ===
  // =====================================================================================
  async check_all(parseResponse) {
    const { data } = await Vue.axios.get(URLS.alert_test.check_all);
    return parseResponse(data);
  },
  async check_no_data(callBackFunction) {
    const { data } = await Vue.axios.get(URLS.alert_test.check_no_data);
    return callBackFunction(data);
  },
  async check_range(callBackFunction) {
    const { data } = await Vue.axios.get(URLS.alert_test.check_range);
    return callBackFunction(data);
  },
  async check_diff(callBackFunction) {
    const { data } = await Vue.axios.get(URLS.alert_test.check_diff);
    return callBackFunction(data);
  },

  async clear_sms_wait(params, callBackFunction) {
    const { data } = await Vue.axios.get(`${URLS.alert_test.clear_sms_wait}?phone=${params}`);
    return callBackFunction(data);
  },

  async set_test_data(rc,value,sec, callBackFunction) {
    const { data } = await Vue.axios.get(`${URLS.alert_test.set_test_data}?rc=${rc}&value=${value}&sec=${sec}`);
    return callBackFunction(data);
  },

  async clear_test_data(params, callBackFunction) {
    const { data } = await Vue.axios.get(`${URLS.alert_test.clear_test_data}?rc=${params}`);
    return callBackFunction(data);
  },

  // =====================================================================================
  // === pigcount controller ===
  // =====================================================================================
  async roomSummary(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.pigcount.roomSummary,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  // =====================================================================================
  // === all controller ===
  // =====================================================================================
  async baseCodeList(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.all.baseCodeList,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  // =====================================================================================
  // === fog ===
  // =====================================================================================
  async get(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.fog.get,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  async set(params, callBackFunction) {
    const { data } = await Vue.axios.post(
      URLS.fog.set,
      JSON.stringify(params)
    );
    return callBackFunction(data);
  },
  // =====================================================================================
  // === redis controller ===
  // =====================================================================================
  async redisLastValue(params, parseResponse) {
    const { data } = await Vue.axios.get(
      URLS.redis.lastValue + "/" + params.sensor_mac
    );
    return parseResponse(data);
  },
  // =====================================================================================
  // === mongo controller ===
  // =====================================================================================
  async mongoTrand(params, parseResponse) {
    const { data } = await Vue.axios.post(
      URLS.mongo.trend,
      JSON.stringify(params)
    );
    return parseResponse(data);
  },
};

export default Apis;
